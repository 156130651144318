<template>
  <div>
    <maintenance-page
      :optionsname="$options.name"
      :singular="displayAsSingular"
      :plural="displayAsPlural"
      :headers="headers"
      :items="maintenanceState"
      :selectedkey="plans.selectedItem"
      @update="onUpdate"
      @delete="onDelete"
      @dblclick="onDblClick"
    >
      <template #keyselection>
        <v-container>
          <v-row
            v-if="customers.data.length > 1"
            no-gutters
          >
            <v-col
              cols="12"
            >
              <select-customer
                id="2-maintain-customer"
                v-model="customers.selectedItem"
                :appendhint="`to select from its ${displayAsPluralPlan}`"
              />
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col
              cols="12"
            >
              <select-plan
                id="2-maintain-plan"
                v-model="plans.selectedItem"
                :appendhint="`to view its ${displayAsPlural}`"
                :disableIconNav="!users.isAdmin"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </maintenance-page>

    <div style="display: none;">
      <v-list-item
        id="navToChildMaintenance"
        to="../assemblies"
      />
    </div>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import MaintenancePage from '@/components/maintenance/MaintenancePage'
  import SelectCustomer from '@/components/keys/SelectCustomer'
  import SelectPlan from '@/components/keys/SelectPlan'

  export default {
    name: 'DivisionsView',

    components: {
      MaintenancePage,
      SelectCustomer,
      SelectPlan,
    },

    data () {
      return {
        headers: [],
      }
    },

    computed: {
      appHeaders: get('app/headers'),
      users: get('user/users'),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      displayAsPluralPlan: get('plan/displayAsPlural'),
      displayAsSingular: get('division/displayAsSingular'),
      displayAsPlural: get('division/displayAsPlural'),
      maintenanceState: get('division/divisions'),
    },

    created () {
      this.initialize()
    },

    methods: {
      setupHeaders () {
        const headerId = { ...this.appHeaders.headerId }

        headerId.width = '10%'
        this.maintenanceState.headers.headerDescription.width = '89%'

        this.headers = []

        this.headers.push(headerId)
        this.headers.push(this.maintenanceState.headers.headerDescription)
        this.headers.push(this.appHeaders.headerActions)
      },

      initialize () {
        this.setupHeaders()
      },

      onUpdate (dataItem) {
        dataItem.plan_id = this.plans.selectedItem.plan_id

        this.$store.dispatch('division/update', dataItem)
      },

      onDelete () {
        this.$store.dispatch('division/delete')
      },

      onDblClick (selectedItem) {
        this.$store.dispatch('division/setSelected', selectedItem)

        document.getElementById('navToChildMaintenance').click()
      },
    },
  }
</script>
